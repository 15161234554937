import styles from "./Frame.module.scss";
import Stack from "../components/svg/stack";
import {
  Link
} from "react-router-dom";
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// sections 
import WobbleSection from "../components/wobble";
import FeaturedSection from "../components/featured";
import ReviewSection from "../components/review";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
  once: true
});

const pad = [
  { title: 'Data-Driven Insights', body: 'Make informed decisions with real-time analytics and predictive hiring trends.' },
  { title: 'Seamless Collaboration', body: 'Collaborate effortlessly with your team, streamlining the hiring process.' },
  { title: 'Global Reach', body: 'Tap into a vast, diverse talent pool spanning the globe..' }
]

const footerLinks = {
  Platform: [
    { title: 'Prospect & Enrich', link: '#' },
    { title: 'Platform Overview', link: '#' },
    { title: 'Engage', link: '#' },
    { title: 'End to end workflows', link: '#' },
  ],
  Resources: [
    { title: 'Knowledge Base', link: '#' },
    { title: 'Webinars', link: '#' },
    { title: 'Privacy Center', link: '#' },
    { title: 'Success Stories', link: '#' },
  ],
  Company: [
    { title: 'About Talen ai', link: '#' },
    { title: 'Careers', link: '#' },
    { title: 'Trust & Security', link: '#' },
    { title: 'Reviews', link: '#' },
  ],
}

const infoLinks = [
  { title: 'Privacy', link: '#' },
  // { title: 'Terms', link: '#' },
  // { title: 'How it works?', link: '#' },
]

const navLinks = [
  { title: 'Features', link: '/#features' },
  { title: 'Why Talen.ai', link: '/#why-talen' },
  { title: 'Reviews', link: '/#reviews' },
  { title: 'Pricing', link: '/contact-us' },
]

const Frame = () => {
  const [currentPath, setPath] = useState(null);
  const [state, setState] = useState({
    isPaneOpenLeft: false,
  });
  let location = useLocation();
  React.useEffect(() => {
    setPath(`/${location.hash}`)
  }, [location]);

  return (
    <>
      {/* header */}
      <section className={`${styles.frame} transIn`}>
        {/* header */}
        <div className={`${styles.frame__container} align_between`}>
          {/* top bar  */}
          <div className={styles.frame1}>
            <nav className={`align_between`}>
              {/* logo */}
              <Link to='/'>
                <img
                  className={styles.whiteVersion013}
                  alt=""
                  src="/white-version01-3@2x.png"
                />
              </Link>
              {/* top bar  */}
              <ul className={styles.nav_link}>
                {navLinks.map((element, id) => {
                  return <li key={id + 3434}>
                    <a className={element.link === currentPath ? styles.activeLink : 'null'} href={element.link}>
                      <span>{element.title}</span>
                    </a>
                  </li>
                })}
              </ul>
              {/* auth */}
              <div className="align_between">
                <Link to="/contact-us" className={styles.signUp}>Sign Up</Link>
                <a href="https://app.talen.ai/auth/login" className={styles.signIn}><span>Sign In</span></a>
              </div>

              <button title="toggle links" className={styles.panelButton} onClick={() => setState({ isPaneOpenLeft: true })}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path className={styles.fill} d="M6 7H11C11.2652 7 11.5196 7.10536 11.7071 7.29289C11.8946 7.48043 12 7.73478 12 8C12 8.26522 11.8946 8.51957 11.7071 8.70711C11.5196 8.89464 11.2652 9 11 9H6C5.73478 9 5.48043 8.89464 5.29289 8.70711C5.10536 8.51957 5 8.26522 5 8C5 7.73478 5.10536 7.48043 5.29289 7.29289C5.48043 7.10536 5.73478 7 6 7ZM13 15H18C18.2652 15 18.5196 15.1054 18.7071 15.2929C18.8946 15.4804 19 15.7348 19 16C19 16.2652 18.8946 16.5196 18.7071 16.7071C18.5196 16.8946 18.2652 17 18 17H13C12.7348 17 12.4804 16.8946 12.2929 16.7071C12.1054 16.5196 12 16.2652 12 16C12 15.7348 12.1054 15.4804 12.2929 15.2929C12.4804 15.1054 12.7348 15 13 15ZM6 11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11Z" fill="white" />
                </svg>
              </button>
            </nav>
          </div>

          {/* bottom section  */}
          <div className={`${styles.frame2} align_between`}>
            {/* left side */}
            <div className="align_between flex-column">
              <h1 className={styles.onePromptFind}>
                One prompt, Find all the best talents
              </h1>
              <article className={styles.theAiPowered}>
                The AI powered recruiting software you'll ever need to find, connect,
                and hire qualified candidates across the globe.
              </article>
              <div className={`${styles.vectorParent} align_between`}>
                <Link to="/contact-us" className={`${styles.signIn} ${styles.getStarted}`}><span>Get Started</span></Link>
                <a href="https://www.youtube.com/watch?v=6MCr6kiw-tw" target="_blank" className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="/group-427318644.svg"
                  />
                  <div className={styles.howItWorks}>How it works</div>
                </a>
              </div>
            </div>
            {/* right image  */}
            <aside className={styles.webImage2b1}>
              <img
                alt=""
                src="/webimage-2b-1@2x.png"
              />
            </aside>
          </div>
        </div>
      </section >
      {/* featured */}
      <FeaturedSection styles={styles} />
      {/* wobble */}
      <WobbleSection styles={styles} />
      {/* pro */}
      <section className={`${styles.pro__frame} transIn`}>
        <div className={styles.pro__container}>

          <h3>Start Hiring Like A Pro</h3>
          <p> A robust recruiting tool to find, engage and hire qualified candidates within minutes.</p>

          <Link to="/contact-us" className={`${styles.signIn} ${styles.getStarted}`}><span>Get Started</span></Link>
        </div>
      </section>
      {/* why */}
      <section className="p-0" id='why-talen'>
        <section className={`${styles.why__frame} transIn`}>
          <div className={`${styles.frame__container}`}>
            <div className={`${styles.why__content}`}>
              {/* header */}
              <div data-aos="fade-right"
                data-aos-duration="500"
                data-aos-easing="ease-in-out" className={styles.header}>
                <span className={styles.line}></span>
                <p>Why Choose Talen ai</p>
              </div>

              <h3 data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in-out">A solution for every recruitment challenge</h3>
              <h4 data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in-out">Our advanced algorithms scan the entire web to identify top talent that perfectly matches your requirements. This tool precisely matches job requirements with candidate skills and experience, cultural fit, and past performance ensuring you find the perfect fit for each role.</h4>
              <div className={styles.content__wrapper}>

                {pad.map((element, id) => {
                  return <div data-aos="fade-up"
                    data-aos-duration={id * 200 + 400}
                    data-aos-easing="ease-in-out" key={id + 7764} className={styles.pad}>
                    <div className={styles.pad__content}>
                      <div className={styles.box__content}>
                        <Stack />
                      </div>
                      <h5>{element.title}</h5>
                      <h6>{element.body}</h6>
                    </div>
                  </div>
                })}

              </div>

            </div>
          </div>
        </section>
      </section>
      {/* review */}
      <ReviewSection styles={styles} />
      {/* cto */}
      <section className={`${styles.cto__frame} transIn`}>
        <div className={`${styles.frame__container}`}>
          <div className={`${styles.cto__content}`}>

            <div
              data-aos="fade-right"
              data-aos-duration="400"
              data-aos-easing="ease-in-out"
              className={styles.left__text}>
              <h3>Are you a talent looking for a job?</h3>
              <h4>Check out talen.ai's Job board to land your next Job</h4>
            </div>

            <div data-aos="fade-left"
              data-aos-duration="400"
              data-aos-easing="ease-in-out" className={styles.right__sub}>
              <p>You are one click away from finding your dream job</p>
              <a href="https://app.talen.ai/job-board" className={`${styles.signIn}`}><span>Get Started</span></a>
            </div>
          </div>
        </div>
      </section>
      {/* footer */}
      <section className={`${styles.footer__frame} transIn`}>
        <div className={styles.frame__container}>
          {/* <div className={styles.footer__content}>
            {Object.keys(footerLinks).map(key => {
              return <ul key={key}>
                <li><h3>{key}</h3></li>
                {footerLinks[key].map((element, id) => {
                  return <li key={(key + id + 443232)}>
                    <Link to={element.link}>
                      <span>{element.title}</span>
                    </Link>
                  </li>
                })}
              </ul>
            })}
          </div> */}
          <div className={`${styles.footer__content} ${styles.footer__bottom}`}>
            <ul>
              {infoLinks.map((element, id) => {
                return <li key={id + 24343}>
                  <h4>&copy; 2023 Talen.ai</h4>
                </li>
              })}
            </ul>
          </div>
        </div>
      </section>

      {/* panel */}
      <SlidingPane
        isOpen={state.isPaneOpenLeft}
        title={null}
        closeIcon={null}
        from="left"
        width="200px"
        onRequestClose={() => setState({ isPaneOpenLeft: false })}
      >
        <div>
          <nav className={`align_between`}>
            {/* logo */}
            <div className="align_between">
              <Link onClick={() => setState({ isPaneOpenLeft: false })} to='/'>
                <img
                  className={styles.whiteVersion013}
                  alt=""
                  src="/white-version01-3@2x.png"
                />
              </Link>

              <button title="Close" className={`${styles.panelButton} panelButton ${styles.noHover}`} onClick={() => setState({ isPaneOpenLeft: false })}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path className={styles.stroke} d="M11.25 3.75L3.75 11.25M3.75 3.75L7.5 7.5L11.25 11.25" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
            {/* top bar  */}
            <ul className={styles.nav_link}>
              {navLinks.map((element, id) => {
                return <li key={id + 3434}>
                  <a className={element.link === currentPath ? styles.activeLink : 'null'} onClick={() => setState({ isPaneOpenLeft: false })} href={element.link}>
                    <span>{element.title}</span>
                  </a>
                </li>
              })}
            </ul>
            {/* auth */}
            <div className="align_between">
              <Link to="/contact-us" className={styles.signUp}>Sign Up</Link>
              <a href="https://app.talen.ai/auth/login" className={`${styles.signIn} signIn`}><span>Sign In</span></a>
            </div>

          </nav>
        </div>
      </SlidingPane>
    </>
  );
};

export default Frame;
