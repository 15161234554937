import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const content = [
    { name: 'yahoo', link: 'https://finance.yahoo.com/news/revolutionizing-talent-acquisition-talen-ai-200800952.html?guccounter=1&guce_referrer=YW5kcm9pZC1hcHA6Ly9jb20uZ29vZ2xlLmFuZHJvaWQuZ20v&guce_referrer_sig=AQAAAGZW9qQA4HHpBBqEtX0zp5zRAbQmzduCgv6Kmtm4_7AQ6vUqU8IBv-d6b9pbbr7pNTVo5S7lNsRUmJaSv-LNIjITxpXdFOkHoYA0bBB-nKceoktiEzGykpUM8RfBLrFIrh2-7rmME41OZDmCfJZqZybwRMZ1c3klUkyTv_fcqe49' },
    { name: 'business insider', link: 'https://markets.businessinsider.com/news/stocks/revolutionizing-talent-acquisition-talen-ai-introduces-groundbreaking-ai-platform-for-global-recruiting-1032824500' },
    { name: 'bloomberg', link: 'https://www.bloomberg.com/press-releases/2023-11-15/revolutionizing-talent-acquisition-talen-ai-introduces-groundbreaking-ai-platform-for-global-recruiting' },
    { name: 'fox', link: 'https://fox47.marketminute.com/article/newsfile-2023-11-15-revolutionizing-talent-acquisition-talen-ai-introduces-groundbreaking-ai-platform-for-global-recruiting' },
    { name: 'barchart', path: true, link: 'https://www.barchart.com/story/news/22050457/revolutionizing-talent-acquisition-talen-ai-introduces-groundbreaking-ai-platform-for-global-recruiting' },
    { name: 'national post', link: 'https://nationalpost.com/author/wsnewsfile/187453-Revolutionizing-Talent-Acquisition-Talen-Ai-Introduces-Groundbreaking-AI-Platform-for-Global-Recruiting' },
    { name: 'paris star', link: 'http://parisstaronline.com/newsfile/187453-Revolutionizing-Talent-Acquisition-Talen-Ai-Introduces-Groundbreaking-AI-Platform-for-Global-Recruiting' },
    { name: 'canoe', link: 'https://canoe.com/author/wsnewsfile/187453-Revolutionizing-Talent-Acquisition-Talen-Ai-Introduces-Groundbreaking-AI-Platform-for-Global-Recruiting' },
]
export default function FeaturedSection({ styles }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return <section className={`${styles.featured_frame} transIn`}>
        {/* featured */}
        <div className={`${styles.frame__container}`}>
            <div className={styles.featured}>
                <p>As Featured On</p>

                {/* <div className={styles.flex__featured}>
                    {content.map((element, id) => {
                        return <a target="_blank" href={element.link} key={id + 334443}><img alt={element.name} src={!element.path ? `/featured/${id + 1}.png` : `/featured/${id + 1}.svg`} /></a>
                    })}
                </div> */}
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Blog Posts"
                        TabIndicatorProps={{ style: { display: 'none' } }}
                    // sx={{
                    //     [`& .${tabsClasses.scrollButtons}`]: {
                    //         '&.Mui-disabled': { opacity: 0.3 },
                    //     },
                    // }}
                    >
                        {content.map((element, id) => {
                            return <Tab key={id + 443} label={<div className={styles.flex__featured}><a target="_blank" href={element.link} key={id + 334443}><img alt={element.name} src={!element.path ? `/featured/${id + 1}.png` : `/featured/${id + 1}.svg`} /></a></div>} />
                        })}
                    </Tabs>
                </Box>
            </div>
        </div>


    </section>
}