
import { Link } from "react-router-dom";
const content = [
    { title: 'Talen ai Features', header: 'Talent Search', body: 'Talent search feature allows you to search for talents from anywhere across the world for diverse roles and get prompt extensive results. All you have to do is input the relevant search parameters for the role you’re hiring for', color: null, order: true },
    { title: 'Talen ai Features', header: 'Job Application', body: 'Create job posts for the roles you are hiring for and share them to other platforms. This feature also allows talents refer other candidates to suitable jobs', color: true, order: true },
    { title: 'Talen ai Features', header: 'Shortlist candidates', body: 'During a search, certain talents profiles will stand out to you, you can shortlist such talents while you continue your search', color: null, order: true },
    { title: 'Talen ai Features', header: 'Job Boards', body: 'You get a customized job board on talen.ai that displays all the current openings in your company. These jobs also appear on Talen.ai main job board.', color: true, order: true },
    { title: 'Talen ai Features', header: 'Create Projects', body: 'Create project feature allows you to create a project for each of your search so as to keep track of search results and talents as well as share with your team members', color: null, order: null },
    { title: 'Talen ai Features', header: 'Customize assessments and interview guidelines.', body: 'You are not just limited to roles search, you can create assessments and interview guidelines for each role you search for in minutes!', color: true, order: null },
    { title: 'Talen ai Features', header: 'Collaborate with team members', body: 'You can collaborate with your team members on a project and also share with them so as to encourage team effort.', color: false, order: true },
    { title: 'Talen ai Features', header: 'Exclude feature', body: 'While inputting your search parameters for a role, you can exclude certain locations, job titles, and companies from popping up in your search results. This means you can restrict your results or customize your search.', color: true, order: true },
]
export default function WobbleSection({ styles }) {
    return <section className="p-0" id='features'>
        {content.map((element, id) => {
            return <section key={id + 453} className={`${styles.wobble__frame} transIn`}>
                {/* featured */}
                <div className={`${styles.frame__container}`}>

                    <div className={`${styles.flex__wobble} ${element.order ? styles.change_order : element.order} ${element.color ? styles.colored__bg : ''}`}>
                        <div data-aos={element.order ? 'fade-left' : 'fade-right'}
                            data-aos-duration="600"
                            data-aos-easing="ease-in-out" className={styles.flex__wobble__texts}>
                            <div data-aos={element.order ? 'fade-left' : 'fade-right'}
                                data-aos-duration="500"
                                data-aos-easing="ease-in-out" className={styles.header}>
                                <span className={styles.line}></span>
                                <p>{element.title}</p>
                            </div>

                            <h3 data-aos={element.order ? 'fade-left' : 'fade-right'}
                                data-aos-duration="600"
                                data-aos-easing="ease-in-out">{element.header}</h3>
                            <h4 data-aos={element.order ? 'fade-left' : 'fade-right'}
                                data-aos-duration="700"
                                data-aos-easing="ease-in-out">{element.body}</h4>

                            <Link to="/contact-us">
                                <span>Explore Talen.ai</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M10.0062 17.0075L15.0104 12.0117L10.0147 7.0075" stroke="#1455FE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg></Link>

                        </div>
                        <div data-aos={element.order ? 'fade-right' : 'fade-left'}
                            data-aos-duration="400"
                            data-aos-easing="ease-in-out" className={styles.flex__wobble__img}>
                            <img alt={element.header} src={`/wobble/${id + 20}.png`} />
                        </div>
                    </div>
                </div>
            </section >
        })}
    </section >
}