
import ArrowLeft from "../components/svg/arrowleft";
import ArrowRight from "../components/svg/arrowRight";

import { useState } from "react";

const content = [
    {
        review: 'Working with this Talen. Ai has been a game-changer for our hiring process. The advanced algorithms and machine learning capabilities significantly reduced the time it took to identify and connect with top-tier candidates.',
        user: {
            title: 'Chief Technology Officer',
            job_type: 'Health startup company',
        }
    },
    {
        review: `The system's ability to analyze resumes and match candidates based on skills and cultural fit is impressive.It's like having a personal assistant for recruiting, providing them with a shortlist of highly qualified candidates.`,
        user: {
            title: 'Chief Executive Officer',
            job_type: 'Property Startup Company',
        }
    },
    {
        review: `This AI platform not only met but exceeded our expectations. The interface is user-friendly, making it easy for our HR team to navigate through the entire recruitment pipeline. The platform's predictive analytics ensured that we were targeting the right talent pools, resulting in quicker and more accurate hires.It's the efficiency boost we were looking for!`,
        user: {
            title: 'Regional Director',
            job_type: 'Mobility Company',
        }
    },
]

export default function ReviewSection({ styles }) {

    const [currentRev, setCurrentRev] = useState(0)
    const [maxLength] = useState(content.length)

    function handleRewviewButton(val) {
        let current = structuredClone(currentRev)
        setCurrentRev(null)
        if (val) current += 1
        else current = Math.abs(current - 1)

        if (current >= maxLength) current = 0
        // setTimeout(() => {
        setCurrentRev(current)
        // }, 200);

    }

    return <section className="p-0 pt-55" id='reviews'>
        <section className={`${styles.review__frame} transIn`}>
            <div className={`${styles.frame__container}`}>
                <div className={`${styles.review__content}`}>

                    <div data-aos="fade"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out" className={styles.left__text}>
                        <img src="/user.jpg" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="307" height="395" viewBox="0 0 307 395" fill="none">
                            <path d="M306.5 150C306.5 67.4334 239.567 0.5 157 0.5H0.5V245C0.5 327.567 67.4334 394.5 150 394.5H297C302.247 394.5 306.5 390.247 306.5 385V150Z" stroke="#FFA500" />
                        </svg>
                    </div>

                    <div className={styles.right__sub}>
                        {/* header */}
                        <div data-aos="fade-right"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-out" className={styles.header}>
                            <span className={styles.line}></span>
                            <p>Reviews</p>
                        </div>

                        {/* controller */}
                        <div data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-easing="ease-in-out" className={styles.controller}>
                            <button onClick={() => { handleRewviewButton(0) }} title="Prev"><ArrowLeft /></button>
                            <button onClick={() => { handleRewviewButton(1) }} title="Next"><ArrowRight /></button>
                        </div>

                        {/* review__box */}
                        <div className={styles.review__box}>
                            <h3>Customer Stories</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                                <path d="M43.1569 37.6295C47.1667 33.2775 46.763 27.6887 46.7502 27.625V10.625C46.7502 10.0614 46.5263 9.52091 46.1278 9.1224C45.7293 8.72388 45.1888 8.5 44.6252 8.5H31.8752C29.5314 8.5 27.6252 10.4061 27.6252 12.75V27.625C27.6252 28.1886 27.8491 28.7291 28.2476 29.1276C28.6461 29.5261 29.1866 29.75 29.7502 29.75H36.291C36.2461 30.8006 35.9322 31.822 35.3794 32.7165C34.2999 34.4186 32.2662 35.581 29.3316 36.1675L27.6252 36.5075V42.5H29.7502C35.6641 42.5 40.1755 40.8616 43.1569 37.6295ZM19.767 37.6295C23.779 33.2775 23.3731 27.6887 23.3604 27.625V10.625C23.3604 10.0614 23.1365 9.52091 22.738 9.1224C22.3394 8.72388 21.7989 8.5 21.2354 8.5H8.48535C6.14148 8.5 4.23535 10.4061 4.23535 12.75V27.625C4.23535 28.1886 4.45923 28.7291 4.85775 29.1276C5.25626 29.5261 5.79677 29.75 6.36035 29.75H12.9011C12.8562 30.8006 12.5423 31.822 11.9895 32.7165C10.91 34.4186 8.87635 35.581 5.94173 36.1675L4.23535 36.5075V42.5H6.36035C12.2742 42.5 16.7856 40.8616 19.767 37.6295Z" fill="#E5E5E5" />
                            </svg>
                            {currentRev !== null && <div className="transIn">
                                <h4>{content[currentRev].review}</h4>
                                <p>{content[currentRev].user.title}</p>
                                <span>{content[currentRev].user.job_type} </span>
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </section>
}